import * as Cookie from './Cookie';
export const SUCCESS = 'SUCCESS';
export const REQUESTING = 'REQUESTING';
export const ERROR = 'ERROR';
export const FAILURE = 'FAILURE';
export const ACCESS_TOKEN = 'accesToken';
export const USER_DETAILS = 'userDetails';
// export const META_DATA = "meta";
export const UID = 'id';
export const OPEN = 'Open';
export const CLOSE = 'Close';
export const CLOSED = 'Closed';
export const ACCEPT = 'Accepted';
export const BLOCKED = 'Blocked';
export const BLOG_COUNT = 'blogCount';
export const isUserLogedIn = Cookie.getCookie(ACCESS_TOKEN) ? true : false;

export const GROUP_DETAIL_STORAGE = 'GROUP_DETAIL_STORAGE';
export const URL = "https://thestarinme.com";
export const API_URL_ROOT = `${URL}/api/v1`;
export const IMAGE_URL_ROOT = `${URL}/uploads/`;
export const REDIRECT_URI = `${URL}/linkedin`;
export const RAZOR_PAY_ID = "rzp_live_ckglUfAf8UKN3z";
export const GOOGLE_ANALYTIC_TRACKING = 'UA-123149508-1';
export const MICROLINK = "XZB7lWhU4y6XFliWX0fgF1l0fkwrGR7e2Vyt4SHR";
export const LINKEDIN_CLIENT_ID = "86tqythwdgzpuv";
export const GOOGLE_CLIENT_ID = '436581195458-4141qddi6npjei2e4akp1796b3cerbt7.apps.googleusercontent.com';
export const CAPTCHA_KEY = '6Ld-C8oaAAAAANECiE6Zogmw_w3MyF1heVe7X7N7';
// Group status type
export const REQUESTED = 'Requested';
export const CANCEL_REQUEST = 'Cancel Request';
export const ADMIN_REQUESTED = 'Admin Requested';
export const JOINED = 'Joined';
export const INVITED = 'Invited';
export const CANCELLED = 'Cancelled';

//url
export const HOME = '/';
export const LINKCHECK = '/linkcheck';
export const BLOG = '/resources';
export const BLOG_DETAILS_WITHOUT_ID = '/resourcedetails';
export const BLOG_DETAILS = '/resourcedetails/:blogName/:blogId';
export const EVENT = '/learning';
export const EVENT_DETAILS_WITHOUT_ID = '/learningdetails';
export const PREMIUM_BUY = "/buypremium";
export const CORPORATE = "/corporate";
export const PREMIUM_CONTENT_DETAILS_WITHOUT_ID = '/exclusivecontentdetails';
export const EVENT_DETAILS = '/learningdetails/:eventName/:eventId';
export const PREMIUM_CONTENT_DETAILS = '/exclusivecontentdetails/:premiumcontentName/:premiumcontentId';
export const MARKET_DETAILS = '/careerdetails';
export const MARKET_DETAILS_WITH_ID = '/careerdetails/:marketname/:marketId';
export const ABOUT_US = '/aboutus';
export const GUIDE = '/guide';
export const MORE = '/more';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const VISUAL_PROFILE = '/visual-profile';
export const SEEK_GUIDE = '/seek-guide';
export const NETWORKING_CREATE = '/networking-create';
export const PROFILE = '/profile';
export const FEED = '/';
export const FEED_DETAILS = '/feeddetails/:feedId';
export const FEED_DETAILS_WITHOUT_ID = '/feeddetails';
export const CONNECTION = '/connections';
export const SETTING = '/settings';
export const MESSAGES_URL = '/messages';
export const EULA = '/eula';
export const NOTFOUND = '/404-not-found';
export const UNSUBSCRIBE = '/unsubscribe';
export const SET_PASSWORD = '/reset-password';
export const WELCOME = '/welcome';
export const SHOW_NOTIFICATION = 'showNotification';
export const URL_REGEX = /((http|https)?:\/\/[^\s]+)/g;
export const MARKET_PLACE = '/careerAccelerator';
export const SEARCH_PAGE = '/search';
export const BOOKMARK = '/bookmark';
export const NOTIFICATION_URL = '/notification';
export const GROUPS_URL = '/groups';
export const CREATE_GROUP = '/createGroup';
export const GROUP_DETAILS = '/groups/:name/:groupId';
export const GROUP_SETTINGS_WITHOUT_ID = '/groupSettings';
export const GROUP_SETTINGS = '/groupSettings/:groupId';
export const GROUP_INFO = '/groups/:groupId';
export const COUNTRY_CITY = 'COUNTRY_CITY';
export const KNOWLEDGE = '/exclusivecontent';
export const NEWFOOTERHOME = '/contact-us';
export const JOBBOARDS = '/jobs';
export const JOBDETAILPAGE = '/jobs/:jobId';
export const GLOBALEVENT = '/dei';
export const GLOBALEVENTSREGISTER = '/dei/calendar';
export const GLOBALEVENTSHOME = '/library';
export const LEADERSHIPPROGRAM = '/leadershipprogram'
export const CONTINUOUSLEARNING = '/continuouslearning'
export const FLYINGSTART = '/flyingstart'
export const FASTTRACK = '/fasttrack'
export const LEADERSHIPEDGE = '/leadershipedge'
export const ADAPTIVEEDGE = '/adaptiveedge'
export const LEADTHEDIALOGUE = '/leadthedialogue'
export const MONTH = [{
    name: 'Month',
    key: 0,
  },
  {
    name: 'January',
    key: 1,
  },
  {
    name: 'February',
    key: 2,
  },
  {
    name: 'March',
    key: 3,
  },
  {
    name: 'April',
    key: 4,
  },
  {
    name: 'May',
    key: 5,
  },
  {
    name: 'June',
    key: 6,
  },
  {
    name: 'July',
    key: 7,
  },
  {
    name: 'August',
    key: 8,
  },
  {
    name: 'September',
    key: 9,
  },
  {
    name: 'October',
    key: 10,
  },
  {
    name: 'November',
    key: 11,
  },
  {
    name: 'December',
    key: 12,
  },
];

export const YEAR = [
  'Year',
  '2022',
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009',
  '2008',
  '2007',
  '2006',
  '2005',
  '2004',
  '2003',
  '2002',
  '2001',
  '2000',
  '1999',
  '1998',
  '1997',
  '1996',
  '1995',
  '1994',
  '1993',
  '1992',
  '1991',
  '1990',
  '1989',
  '1988',
  '1987',
  '1986',
  '1985',
  '1984',
  '1983',
  '1982',
  '1981',
  '1980',
  '1979',
  '1978',
  '1977',
  '1976',
  '1975',
  '1974',
  '1973',
  '1972',
  '1971',
  '1970',
  '1969',
  '1968',
  '1967',
  '1966',
  '1965',
  '1964',
  '1963',
  '1962',
  '1961',
];
export const EXPYEAR = [
  'Year',
  '2050',
  '2049',
  '2048',
  '2047',
  '2046',
  '2045',
  '2044',
  '2043',
  '2042',
  '2041',
  '2040',
  '2039',
  '2038',
  '2037',
  '2036',
  '2035',
  '2034',
  '2033',
  '2032',
  '2031',
  '2030',
  '2029',
  '2028',
  '2027',
  '2026',
  '2025',
  '2024',
  '2023',
  '2022',
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009',
  '2008',
  '2007',
  '2006',
  '2005',
  '2004',
  '2003',
  '2002',
  '2001',
  '2000',
  '1999',
  '1998',
  '1997',
  '1996',
  '1995',
  '1994',
  '1993',
  '1992',
  '1991',
  '1990',
  '1989',
  '1988',
  '1987',
  '1986',
  '1985',
  '1984',
  '1983',
  '1982',
  '1981',
  '1980',
  '1979',
  '1978',
  '1977',
  '1976',
  '1975',
  '1974',
  '1973',
  '1972',
  '1971',
  '1970',
  '1969',
  '1968',
  '1967',
  '1966',
  '1965',
  '1964',
  '1963',
  '1962',
  '1961',
];
export const EMAIL_REGEX = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
